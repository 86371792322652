import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { locationTypes } from '../../../constants/components';
import { Components } from '../../../types/components.types';
import { RootWidgetControllerParams } from '../../../types/map-plugin.types';
import { getComponents } from '../components';
import { MapWidgetViewer } from '../viewer/viewer';
import { MapWidgetModal } from '../viewer/viewer.model';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IWidgetControllerConfig } from '@wix/native-components-infra';
import { reportWidgetPageLoaded } from '../../../services/BiLogger/BiLogger';

export class RootMapPlugin {
  public controllerConfig: IWidgetControllerConfig;
  public wixSdkAdapter: WixOOISDKAdapter;
  public flowAPI: PlatformControllerFlowAPI;
  public components: Components;
  public mapWidgetModal: MapWidgetModal;
  public mapWidgetViewer: MapWidgetViewer;
  public biLogger;

  constructor(public readonly controllerParams: RootWidgetControllerParams) {
    this.controllerConfig = controllerParams.controllerConfig;
    this.wixSdkAdapter = new WixOOISDKAdapter(
      controllerParams.controllerConfig.wixCodeApi,
      controllerParams.controllerConfig.platformAPIs,
      controllerParams.controllerConfig.appParams,
      controllerParams.controllerConfig.compId,
      controllerParams.flowAPI.experiments,
    );
    this.flowAPI = controllerParams.flowAPI;
    this.components = getComponents(controllerParams.$w);
    this.mapWidgetModal = new MapWidgetModal({
      wixSdkAdapter: this.wixSdkAdapter,
      reportError: this.flowAPI.reportError,
    });
    this.mapWidgetViewer = new MapWidgetViewer({
      components: this.components,
      flowAPI: this.flowAPI,
    });
    this.biLogger = controllerParams.flowAPI.essentials
      .biLoggerFactory()
      .logger();
  }

  private async reportPluginLoaded(bookingsServiceId) {
    const { isEditor, isEditorX } = this.controllerParams.flowAPI.environment;
    reportWidgetPageLoaded(this.biLogger, {
      is_over_editor: isEditor || isEditorX,
      serviceId: bookingsServiceId,
      widget_name: 'map_plugin',
      origin: 'map_plugin',
    });
  }

  private getServiceId() {
    return this.controllerParams.$widget.props.bookingsServiceId;
  }

  private getLocationType() {
    return this.controllerParams.$widget.props.locationType;
  }

  private isBusinessLocaiton() {
    return this.getLocationType() === locationTypes.BUSINESS_LOCATION;
  }

  private isEditorOrEditorX() {
    return (
      this.flowAPI.environment.isEditor || this.flowAPI.environment.isEditorX
    );
  }

  private isPreviewOrViewer() {
    return (
      this.flowAPI.environment.isPreview || this.flowAPI.environment.isViewer
    );
  }

  private async initPlugin(bookingsServiceId: string) {
    if (this.isPreviewOrViewer()) {
      if (this.isBusinessLocaiton()) {
        const businessLocation =
          await this.mapWidgetModal.getServiceWithBusinessLocation({
            wixSdkAdapter: this.wixSdkAdapter,
          });
        this.mapWidgetViewer.markersBusinessLocatinView(businessLocation);
      } else {
        const catalogData = await this.mapWidgetModal.getServiceCatalog({
          bookingsServiceId,
          wixSdkAdapter: this.wixSdkAdapter,
        });
        this.mapWidgetViewer.markersView(catalogData);
      }
    }
  }

  public async handleOnPropsChanged(oldProps, newProps): Promise<void> {
    const oldServiceId = oldProps.bookingsServiceId;
    const newServiceId = newProps.bookingsServiceId;
    const oldLocation = oldProps.locationType;
    const newLocation = newProps.locationType;

    if (newServiceId && newServiceId !== oldServiceId) {
      const bookingsServiceId = this.getServiceId();
      this.reportPluginLoaded(bookingsServiceId);
      await this.initPlugin(bookingsServiceId);
    }
    if (newLocation && newLocation !== oldLocation) {
      const bookingsServiceId = this.getServiceId();
      this.reportPluginLoaded(bookingsServiceId);
      this.init();
    }
  }

  async initEditorView() {
    this.reportPluginLoaded('');
    this.mapWidgetViewer.editorView(this.isBusinessLocaiton());
  }

  public async init(): Promise<void> {
    if (this.isEditorOrEditorX()) {
      this.initEditorView();
      return;
    }
  }
}
