import { ControllerParams } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  getCatalogService,
  getCatalogServiceWithBusinessLocation,
} from '../../../api/service-catalog';
import { BulkResponse } from '@wix/ambassador-services-catalog-server/types';

export class MapWidgetModal {
  private wixSdkAdapter: WixOOISDKAdapter;
  private readonly reportError: ControllerParams['flowAPI']['reportError'];

  constructor({ wixSdkAdapter, reportError }) {
    this.wixSdkAdapter = wixSdkAdapter;
    this.reportError = reportError;
  }

  async getServiceCatalog({
    bookingsServiceId,
    wixSdkAdapter,
  }: {
    bookingsServiceId: string;
    wixSdkAdapter: WixOOISDKAdapter;
  }): Promise<BulkResponse> {
    const catalogData = await getCatalogService({
      serviceId: bookingsServiceId,
      wixSdkAdapter,
      onError: this.reportError,
    });

    return catalogData!;
  }

  private getAllLocation(catalogData) {
    let mergedLocations: any[] = [];
    const listLocations = catalogData?.responseServices?.services?.map(
      (service) => {
        return service.schedules?.[0].location
          ? [service.schedules?.[0].location]
          : service.schedules?.[0].availability?.locations;
      },
    );

    for (const locations of listLocations!) {
      mergedLocations = [...mergedLocations, ...locations!];
    }

    return mergedLocations;
  }

  async getServiceWithBusinessLocation({
    wixSdkAdapter,
  }: {
    wixSdkAdapter: WixOOISDKAdapter;
  }) {
    const catalogData = await getCatalogServiceWithBusinessLocation({
      wixSdkAdapter,
      onError: this.reportError,
    });
    let serviceWithBusinessLocation: any[] = [];
    const allLocations = this.getAllLocation(catalogData);
    serviceWithBusinessLocation = allLocations.filter(
      (location) => location?.businessLocation?.default === true,
    );
    return serviceWithBusinessLocation[0];
  }
}
