import { FlowAPI } from '@wix/yoshi-flow-editor';
import { bookingsLocationTypes } from '../../../constants/components';
import {
  getMarkers,
  getLocationType,
  getEditorMarker,
  getBusinessLocationMarkers,
} from '../../../extractors/service-extractor';
import { Components } from '../../../types/components.types';

export class MapWidgetViewer {
  private components: Components;
  private flowAPI: FlowAPI;
  constructor({ components, flowAPI }) {
    this.components = components;
    this.flowAPI = flowAPI;
  }

  emptyStateView() {
    this.components.mapPluginContainer.collapse();
  }

  async markersView(catalogData) {
    switch (getLocationType(catalogData)) {
      case bookingsLocationTypes.BUSINESS:
        const markers = getMarkers(catalogData!);
        if (markers!.length > 0) {
          this.components.googleMaps.markers = markers;
        } else {
          this.emptyStateView();
        }
        break;
      case bookingsLocationTypes.CUSTOM:
      case bookingsLocationTypes.CLIENT_PLACE:
        this.emptyStateView();
        break;
      default:
        break;
    }
  }

  async markersBusinessLocatinView(businessLocation) {
    const markers = getBusinessLocationMarkers(businessLocation);
    if (markers) {
      this.components.googleMaps.markers = [markers];
    } else {
      this.emptyStateView();
    }
  }

  async editorView(isBusinessLocaiton) {
    const key = isBusinessLocaiton
      ? 'app.widget.businessLocation'
      : 'app.widget.serviceLocation';
    console.log('isBusinessLocaiton: ', isBusinessLocaiton);
    console.log('key: ', key);
    const markers = getEditorMarker(this.flowAPI.translations.t(key));
    this.components.googleMaps.markers = markers;
  }
}
