import { BulkResponse } from '@wix/ambassador-services-catalog-server/types';
import { defaultLocation } from '../constants/components';

export const getLocationType = (catalogData: BulkResponse) => {
  const locationType =
    catalogData.responseServices?.services?.[0].schedules?.[0].location
      ?.locationType;

  if (!locationType || !isCourse(catalogData)) {
    return catalogData.responseServices?.services?.[0].schedules?.[0]
      .availability?.locations?.[0].locationType;
  }
  return locationType;
};

export const getEditorMarker = (description) => {
  const { latitude, longitude } = defaultLocation;
  const location = { latitude, longitude };
  return [
    {
      location,
      title: description,
      address: description,
    },
  ];
};

export const getBusinessLocationMarkers = (businessLocation) => {
  return {
    location: getLocation(businessLocation),
    title: getTitle(businessLocation),
    address: getAddress(businessLocation),
  };
};

export const getMarkers = (catalogData: BulkResponse) => {
  const { locations } =
    catalogData.responseServices?.services?.[0].schedules?.[0].availability!;
  const location =
    catalogData.responseServices?.services?.[0].schedules?.[0].location;
  const selectedLocations =
    location && isCourse(catalogData) ? [location] : locations;
  return selectedLocations?.map((selectedLocation) => {
    return {
      location: getLocation(selectedLocation),
      title: getTitle(selectedLocation),
      address: getAddress(selectedLocation),
    };
  });
};

const isCourse = (catalogData: BulkResponse) => {
  return (
    catalogData.responseServices?.services?.[0].schedules?.[0].tags?.[0] ===
    'COURSE'
  );
};

const getLocation = (location) => {
  return location.businessLocation?.address?.geocode;
};

const getTitle = (location) => {
  return location.businessLocation?.name;
};

const getAddress = (location) => {
  return location.businessLocation?.address?.formattedAddress;
};
